import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function Ap16 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Annual Program 2016
                </h1>
                <p>
                    The annual program of the organization for the year 2016 started with Mohd.
                    It happened due to Javed Khan giving information about the works of our organization in front of the people.
                    Many of our colleagues contributed to make this program a success.
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/ap16_001.jpg').default}
                            text="The music program in this program was performed by Kumari Anuradha Shankar and Mohd.
                                    Presented by Nasir Khan. Kumari Anuradha played Sitar."
                        />
                        <CardItem
                            src = {require('../images/ap16_002.jpg').default}
                            text="Shri Sajan Pathak ji presented a program of Ghazal singing."
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/ap16_003.jpg').default}
                            text="Mr. Albret Minz was presented the Rehana Foundation Award for the year 2016. Mr. Albret Minz runs a school for villagers in Chunchuna Pundag of Balrampur district, a very unreachable area."
                        />
                        <CardItem
                            src = {require('../images/ap16_004.jpg').default}
                            text="Mr. Dinesh Sharma showing gratitude on behalf of the organization."
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}

