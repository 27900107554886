import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


function GSP18 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Gandhi Sumiran Program 2018
                </h1>
                <p>
                    The key speaker of the program in Gandhi Sumiran Program 2018 was Professor Apoorvanand of Delhi University who expressed his views on the life and thoughts of Gandhiji. The program was conducted by Javed Khan of our organization, this program was done by Shri Anjani Pandey, the hymn of Mav Gandhi Ji, thanks to all the respected persons present in the program and who cooperated in the program, by Shri Dinesh Sharma of the organization was done. KR Technical College Ambikapur had special support in the Gandhi Sumiran program of 2018 .
                </p>
            </div>
            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/gs18_1.jpg').default}
                            text="In the beginning of Gandhi Sumiran program, Mohd. Javed's speech"
                        />
                        <CardItem
                            src = {require('../images/gs18_7.jpg').default}
                            text="Professor Apoorvanand's lecture on Gandhiji"
                        />
                        <CardItem
                            src = {require('../images/gs18_4.jpg').default}
                            text = "Presentation of Gandhiji's favorite hymn by Shri Anjani at the beginning of the program"
                            path='/awards'
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/gs18_6.jpg').default}
                            text='Professor Apoorvanand answering questions from the press'
                        />
                        <CardItem
                            src = {require('../images/gs18_2.jpg').default}
                            text="audience group
"
                        />
                        <CardItem
                            src = {require('../images/gs18_5.jpg').default}
                            text='Brother Dinesh Sharma giving vote of thanks in the program'
                            path='/awards'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default GSP18
