import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className='footer-container'>
            <div class='footer-links'>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>Address</h2>
                        <div className='footer-contents'>
                            <p>
                                Rehana Foundation,<br/>
                                Behind Chitra Mandir Talkies,<br/>
                                Ambikapur<br/>
                                Pin : 497001<br/>
                                District : Surguja,<br/>
                                State : Chhattisgarh<br/>
                                India
                            </p>
                        </div>
                    </div>
                    <div class='footer-link-items'>
                        <div className='footer-contents'>
                        <h2>Contact Us</h2>
                        <p>
                            +91 8819017977<br/>
                            +91 9425256456<br/>
                            +91 9826150540 <br/>
                        </p>
                        </div>
                    </div>
                    <div className='footer-link-items'>
                        <div className='footer-contents'>
                            <h2>Email</h2>
                            <p>
                                rehanafoundation@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                        <div  className='social-logo'>
                            REHANA FOUNDATION
                        </div>
                    </div>
                    <small class='website-rights'>Rehana Foundation © 2017</small>
                    <a href={"https://www.facebook.com/groups/672949349546257/"}>
                        <img src = {require('../images/fb.png').default}
                             alt = 'facebook'
                        width={80}
                        height={80}
                        />
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Footer;