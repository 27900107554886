import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


function Nv18 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Nirbhay Vivek 2018
                </h1>
                <div className = "contents-text">
                <h5>
                    Rehana Foundation's program - Nirbhay Vivek: Indian Renaissance and Swami Vivekananda - In today's context
                </h5>
                </div>
                <p>
                    At the young age of just 39, who illuminated the whole world with the brilliance of his ideas, in the Indian independence movement whose In the light of the ideas, the agitators searched the direction of the movement, the need to know such Swami Vivekananda is very much for our new generation so that the coming generation can breathe beautiful and open air. We need to go inside Swami Vivekananda and investigate what kind of world he wanted. The world as Swamiji wanted, is the present world the same as the present India.

                    With our keynote speaker Shri Shailendra Kumar, under the chairmanship of Swami Tanmanyanand ji and under the conduct of Shri Prabhunarayan Verma, we tried to understand the glorious history of India and the ideas of Swami Vivekananda in the light of his ideological importance from the Indian Renaissance to the present day situations. Did. The district library got special support in making this program a success.
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/nvp.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/nb18_1.jpg').default}
                            text="Keynote speaker Shri Shailendra Kumar giving his statement"
                        />
                        <CardItem
                            src = {require('../images/nb18_5.jpg').default}
                            text = "President of the program Swami Tanmanand ji giving his statement"                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/nb18_4.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/nb18_3.jpg').default}
                            text="Audience group"
                        />
                        <CardItem
                            src = {require('../images/gs_2019_6.jpg').default}
                            text='Batching keynote speaker'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default Nv18
