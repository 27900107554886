import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function MKZ () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Need Manto 13 Aug 2017
                </h1>
                <p>
                    A program focused on Saadat Hasan Manto, a great scholar of Urdu adab, was organized in Manjusha Computer College, Ambikapur, in which Mr. Vijay Gupta gave his speech on the need of Manto in today's era. Mahesh Verma gave his statement on the ethics of art and on the subject of Manto. The program was conducted by Prabhunarayan Verma and Aabhar Vishal Srivastava. Manjusha Computer College, Namnakala, Ambikapur was a special partner in the program.                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/mj2018_1.jpg').default}
                            text="Inaugurating the program, Mohd. Javed Khan"
                        />
                        <CardItem
                            src = {require('../images/mj2018_2.jpg').default}
                            text="Mr. Vijay Gupta giving a statement"
                        />
                        <CardItem
                            src = {require('../images/mj2018_3.jpg').default}
                            text = "Statement of Shri Mahesh Verma"
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/mj2018_4.jpg').default}
                            text="Shree Prabhu Narayan Varma's stage conduct"
                        />
                        <CardItem
                            src = {require('../images/mj2018_6.jpg').default}
                            text="Audience group"
                        />
                        <CardItem
                            src = {require('../images/mj2018_5.jpg').default}
                            text = "Audience group"
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}
