import React from 'react';
import '../App.css';
import './ContentsSection.css';

function ContentsSection() {
    return (
        <div className={'contents-container'}>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <h1> REHANA FOUNDATION</h1>
            <h4>A non profit Organization</h4>
        </div>
    )
}

export default ContentsSection