import React from 'react';
import '../../App.css';
import './Pages.css';
import {Link} from "react-router-dom";

export default function Programs() {
    return (

        <div className='contents-text'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <h2>Major Programs conducted by the Rehana Foundation</h2>
                <h3>
                    Gandhi Sumiran
                </h3>
            <img
                src = {require('../../images/GandhiSumiran.png').default}
                id="logo"
                alt="Rehana Foundation"
            />
            <div className="container">
                <div className="wrapper">
                    <div className="box a">

                Bapu, who followed the path of non-violence and truth throughout life, not only taught humanity the lesson of humanity but also taught the right way to live life. For Gandhiji, there was no service greater than charity and there was no greater religion than humanity. Bapu may not be alive among us today, but he will live forever through his thoughts. Our organization organizes "Gandhi Sumiran" every year on the death anniversary of Mahatma Gandhi <br/> </div>
                    <div className="box b"> <h3>Check Highlights of Gandhi Sumiran : <br/> </h3>
                    <Link to = '/gsp17'>
                        <button className="custom-btn btn-12"><span>Click!</span><span>2017</span></button>
                    </Link>
                    <Link to = '/gsp18'>
                        <button className="custom-btn btn-12"><span>Click!</span><span>2018</span></button>
                    </Link>
                    <Link to = '/gsp19'>
                        <button className="custom-btn btn-12"><span>Click!</span><span>2019</span></button>
                    </Link> </div> </div> </div>


            <h3>
                NIRBHAY VIVEK
            </h3>
            <img
                src = {require('../../images/nvp.jpg').default}
                id="logo"
                alt="Rehana Foundation"
            />
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                Swami Vivekananda was a scholar of Vedas and a great philosopher who not only worked for the upliftment of India but also taught people the art of living. His ideas for philanthropy, brotherhood, love, self-respect, education and women's emancipation are relevant even today and will continue to show people the way in the times to come.
                        This program organized under the name Nirbhay Vivek is an attempt to know more about him. <br/> </div>
                    <div className="box b">   <h3>Check Highlights of NIRBHAY VIVEK : <br/> </h3>
                <Link to = '/nv18'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2018</span></button> <br/>
                </Link> </div> </div> </div>

            <h3>
                Textile Distribution and Health Program
            </h3>
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                        In the remote villages of Surguja division, our organization does the work of distributing clothes to the needy people and also organizes health camps for the residents of such unaccessible villages so that they can get health benefits. </div>
                    <div className="box b"> <h3>Check Highlights of Cloth Distribution : <br/> </h3>
                <Link to = '/cd16'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2016</span></button>
                </Link>
                <Link to = '/cd17'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2017</span></button>
                </Link>
                <Link to = '/cd18j'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2018 (Jan)</span></button>
                </Link>
                <Link to = '/cd18d'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2018 (Dec)</span></button> <br/>
                </Link> </div> </div> </div>

            <h3>
                Annual Events
            </h3>
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                        Our organization holds its annual program on May every year. In this program, we keep the work done by us in front of all of you and give Rehana Foundation honor to any person nominated by you people who make their best contribution to the society in the field of literature, art or social service. </div>
                    <div className="box b"> <h3>Check Highlights of Annual Event : <br/> </h3>
                <Link to = '/ap16'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2016</span></button>
                </Link>
                <Link to = '/ap17'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2017</span></button>
                </Link>
                <Link to = '/ap18'>
                    <button className="custom-btn btn-12"><span>Click!</span><span>2018</span></button> <br/>
                </Link> </div> </div> </div>

            <h3>
                Other Programs
            </h3>
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                        Our organization organizes many other types of programs, in which literary programs, social cooperation programs, seminars etc. <br/> </div>
                    <div className="box b"><div className="para"> MANTO KI ZAROORAT 2017 &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &nbsp; &emsp; &emsp;  &emsp;
                <Link to = '/mkz'>
                     <t/>
                    <button className="custom-btn btn-12"><span>Here!</span><span>Check</span></button> <br/>
                </Link>
                   PAINTING COMPETITION  2017 &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &nbsp; &emsp; &emsp;
                <Link to = '/pc17'>
                    <button className="custom-btn btn-12"><span>Here!</span><span>Check</span></button> <br/>
                </Link>
                   CYCLING FOR ENVIRONMENT PROTECTION 2012&emsp; &emsp; &nbsp;
                <Link to = '/cep'>
                    <button className="custom-btn btn-12"><span>Here!</span><span>Check</span></button> <br/>

                </Link> </div> </div> </div>
               </div>



        </div>

    );
}