import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';

function Cards () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
            <h1>
                WHO ARE WE
            </h1>
            <p>
                Rehana Foundation was formed in September 2010. We have taken the organization forward only with our mutual cooperation.
            </p>
            <h1>
                WHAT WE DO
            </h1>
            <p>
                Our aim is to establish communal harmony as well as to help the neglected extremely backward people. We go to the unreached areas every year and distribute clothes and blankets to the needy villagers. We arrange health check-up by organizing medical camps in those areas so that they can get the benefit of experienced doctors.
            </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/1.jpg').default}
                            text='Snippets of our journey with the Rehana Foundation so far'
                        />
                        <CardItem
                            src = {require('../images/2.jpg').default}
                            text='The team of Rehana Foundation did a great job of relief work during the floods in Kerala in 2018. Our team went to Kerala with relief material and provided material to the needy, as well as cleaned the houses and provided medical help to the people there'

                        />
                        <CardItem
                            src = {require('../images/inspiration.gif').default}
                            text='Our inspirations'
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/certificate.jpg').default}
                            text='Certificate of Establishment'
                        />

                        <CardItem
                            src = {require('../images/award.jpg').default}
                            text='Rehana Foundation honors a person who has done excellent work in the society every year.'
                            path='/awards'
                        />
                    </ul>


                </div>
            </div>
            {/* <Link to='/certificate' >
                <a href="#" className="link-primary">Certificate of establishment</a>
            </Link> */}
            <div className="im">
            <img src = {require('../images/backG.png').default} alt=""
                 className="center"
            />
            </div>
        </div>
    );
}

export default Cards