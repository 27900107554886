import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function Ap18 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Annual Program 2018
                </h1>
                <p>
                    On May 30, 2018, the "Annual Ceremony" of our organization Rehana Foundation was organized.
                    This year Rehana Foundation Award was given to social worker Shri Shyamlal Vishwakarma Sanmarg Seva Samiti, Mayapur, Ambikapur for his outstanding contribution in the field of social service for the homeless and orphan children. This year, in the music program, the country's eminent young Santoor player
                    Divyansh Srivastava
                    gave his performance, with whom Jugalbandi Tabla Nawaz
                    Shri Anand Kumar Mishra,
                    along with
                    Ms. Swati Tiwari in singing
                    and
                    Shri Pankaj Sharma on harmonium gave accompaniment. Renowned classical singer
                    honored with Rehana Foundation honor in this program, Dr. Ramshankar
                    of BHU Banaras

                    Specially present, under whose guidance the music program was illuminated. The program was started by Mr. Vishal Srivastava.
                    During the program, Mr. Nandlal, who enrolled in the first 10 of Chhattisgarh Board, was given a citation by Rehana Foundation. Nandlal is a boy from a very poor family whose father is an agricultural laborer and mother does household work, even in these difficult circumstances, Nandlal performed very well in the 10th examination. For this, this promising child was given a citation by
                    our institution.
                    The program was organized at Shri Ramakrishna Vivekananda Shala Ghari Chowk, Ambikapur on 30th May 2018 from 7 PM on Wednesday.
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/ap18_1.jpg').default}
                            text="Program started by Shri Vishal Srivastava"
                        />
                        <CardItem
                            src = {require('../images/ap18_2.jpg').default}
                            text="In the program, singing by Ms. Swati Tiwari and accompaniment of Mr. Pankaj Sharma on harmonium."
                        />
                        <CardItem
                            src = {require('../images/ap18_3.jpg').default}
                            text="Respected Dr. Ram Shankar Sir from Banaras Hindu University and Respected Tapan Banerjee Sir presenting the Rehana Foundation Award for the year 2018 to Shri Shyamlal Vishwakarma"
                        />
                        <CardItem
                            src = {require('../images/ap18_4.jpg').default}
                            text="Rehana Foundation's citation to Nandlal, who has registered his name in the state merit list in CG board class 10th by Respected Dr. Ram Shankar Sir, from Banaras Hindu University and Respected Tapan Banerjee Sir."
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/ap18_5.jpg').default}
                            text = "Presentation of the country's eminent young Santoor player Divyansh Srivastava with whom Jugalbandi Tabla Nawaz Anand Mishra ji"
                        />
                        <CardItem
                            src = {require('../images/ap18_6.jpg').default}
                            text ="Giving information about the programs of Rehana Foundation in the program, Shri Dinesh Sharma ji"
                        />
                        <CardItem
                            src = {require('../images/ap18_7.jpg').default}
                            text = "Article published about the Annual Program 2018 in the newspaper"
                        />
                        <CardItem
                            src = {require('../images/ap18_8.jpg').default}
                            text="Audience"
                        />
                        <CardItem
                            src = {require('../images/ap18_9.jpg').default}
                            text="Team Rehana Foundation"
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}

