import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function Cd18J () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Cloth Distribution Program 2018 (January)
                </h1>
                <p>
                    In the month of January, the organization got information about some people who did not have warm clothes, for this hot clothes were distributed by the members of the organization.
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd1.jpg').default}
                            text = "Distribution of warm clothes to the villagers"
                        />
                        <CardItem
                            src = {require('../images/cd2.jpg').default}
                            text = "Kids helping the poor"
                        />
                        <CardItem
                            src = {require('../images/cd3.jpg').default}
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd4.jpg').default}
                            text = "Distribution of warm clothes to the villagers"

                        />
                        <CardItem
                            src = {require('../images/cd5.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/cd6.jpg').default}

                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}
