import React from 'react';
import '../../App.css';
import './Login.css';

export default function Login() {
    return (
        <div className= "tbody">
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
        <div className="login-page">
            <div className="form">
                <form className="login-form acrylic">
                    <span>Login to your account</span>
                    <input type="text" placeholder="Email"/>
                    <input type="password" placeholder="Password"/>
                    <button id="SignIn">Login</button>
                </form>
            </div> </div> </div>
    );
}
