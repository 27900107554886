import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function Cd17 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Cloth Distribution Program 2017
                </h1>
                <p>
                    A medical camp was organized for distribution of normal and warm clothes among the people of village Sitkalo, Kharra, Matringa, Bankesma and many villages situated on the remote hill of Udaipur development block of Surguja. In which our organization Rehana Foundation also distributed clothes, blankets and sanitary pads for women. Even today, when we are walking around with the drum of development in our neck, we come to know the reality of these things by going to these villages, when it comes to know that, in the serious days of their month, women use things like newspaper, paper, sandbags. uses. We should give it to poor women free of cost at ration shops. Our team organized this camp for the betterment of the villagers of this village
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd_bakoi_1.jpg').default}
                            text="Mr. Dinesh Sharma Mr. Kewal Sahu and Javed"
                        />
                        <CardItem
                            src = {require('../images/cd_bakoi_2.jpg').default}
                            text="Organization team"
                        />
                        <CardItem
                            src = {require('../images/cd_bakoi_3.jpg').default}
                            text = "Members of the organization preparing to go to the camp"

                        />
                        <CardItem
                            src = {require('../images/cd_bakoi_4.jpg').default}
                            text = "Shri Kewal Sahu distributing blankets"

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd_bakoi_5.jpg').default}
                            text="Medical Camp Village Bakoi"
                        />
                        <CardItem
                            src = {require('../images/cd_bakoi_6.jpg').default}
                            text="Medical Camp Village Bakoi"
                        />
                        <CardItem
                            src = {require('../images/cd_bakoi_7.jpg').default}
                            text='Shri Dilip Gupta of the organization distributing warm clothes'

                        />
                        <CardItem
                            src = {require('../images/cd_bakoi_8.jpg').default}
                            text = "Institution's Mohd. Javed Khan distributing clothes to children"

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd_bakoi_9.jpg').default}
                            text="Other rural people"
                        />
                        <CardItem
                            src = {require('../images/cd_bakoi_10.jpg').default}
                            text="Other rural people"
                        />
                        <CardItem
                            src = {require('../images/cdp2018_11.jpg').default}
                            text='Distribution material of the organization in village Pendarkhi'
                        />
                        <CardItem
                            src = {require('../images/cdp2018_12.jpg').default}
                            text = "Villagers in camp in village Pendarkhi"

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cdp2018_13.jpg').default}
                            text="Shri TS Dhanjal distributing toffees to the children during the camp"
                        />
                        <CardItem
                            src = {require('../images/cdp2018_014.jpg').default}
                            text="Mr. Dinesh Sharma and Mr. Dilip Gupta distributing blankets"
                        />
                        <CardItem
                            src = {require('../images/cdp2018_015.jpg').default}
                            text = "Institution's Mohd. Javed Khan and Mr. Dilip Gupta distributing blankets"

                        />
                        <CardItem
                            src = {require('../images/cdp2018_16.jpg').default}
                            text = "Institution's Mohd. Javed Khan and Mr. Dilip Gupta distributing blankets"
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}
