import React from 'react';
import '../../App.css';
import './Pages.css';

export default function Programs() {
    return (
        <div className='contents-text'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='sub-contents-text'>
                Rehana Foundation every year tries to recognize such people who do excellent work in the society, whose work is anonymous. In order to recognize such good work and encourage such persons, the organization provides "Rehana Foundation Samman"
            </div>

           <div className="imag"> <img
                src = {require('../../images/awardee2018.jpg').default}
                id="awardee"
                alt="Rehana Foundation"
                width="50px"
                height="300px"
            /> </div>
            <h4>
                Mr. Shyamlal Vishwakarma
            </h4>
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                        <div className="txt">
                For his outstanding contribution made to the homeless and orphan children in the field of social service, the organization was awarded "Rehana Foundation Samman 2018" on 30th May 2018 at the annual function of the organization.
                        </div></div>
                    <div className="box b">
                        <h2>
                            2018
                        </h2> </div> </div> </div>
            <img
                src = {require('../../images/ramshankar.jpg').default}
                id="awardee"
                alt="Rehana Foundation"
            />
            <h4>
                Shri Ram Shankar
            </h4>
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                        <div className="txt">
               The famous classical music singer of Banaras city of Uttar Pradesh, Shri Ram Shankar, was presented "Rehana Foundation Samman 2017" by the institution on 30 May 2017 at the annual function of the institution for his outstanding knowledge and contribution in Indian music .
                        </div> </div>

                    <div className="box b">
                        <h2>
                            2017
                        </h2> </div> </div> </div>

            <h4>
                Shri Bimal Kujur
            </h4>
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                        <div className="txt">
                He lives in Kunkuri village of Batoli development block of Surguja district, is blind himself and runs a school cum hostel for visually impaired children. For his contribution to education and social service, the institution's annual function on May 30 2017 "Rehana Foundation award 2017" awarded.
                        </div> </div>
                    <div className="box b">
                        <h2>
                            2017
                        </h2> </div> </div> </div>
            <img
                src = {require('../../images/awardee2016.jpg').default}
                id="awardee"
                alt="Rehana Foundation"
            />
            <h4>
                Mr. Albret Minj
            </h4>
            <div className="container">
                <div className="wrapper">
                    <div className="box a">
                        <div className="txt">

                A very remote area of Balrampur district, "Chunchuna Pundag", which is an unreachable area, has kept the light of education alive. Mr. Albret Minz was awarded "Rehana Foundation Samman 2016" by our organization for his outstanding work of social service on 30th May 2016 at the annual function of the organization .
                        </div> </div>
                    <div className="box b">
                    <h2>
                        2016
                    </h2> </div> </div> </div>
        </div>
    );
}