import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function CEP () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Cycling for Environment Protection 2012
                </h1>
                <p>
                    Concerned about the deteriorating nature of the environment, our organization's Mr. Dinesh Sharma and Mohd. To spread awareness in Chhattisgarh state, Javed Khan made a program to travel on cycle from Ambikapur to Raipur and to discuss with each and every village found on the way and the villagers, children and teachers residing in that village. This cycle yatra was started on 02 October 2012 from Ambikapur and people were made aware about the environment in every village and city on the way. Along with this, its ill effects were also explained to stop the use of polyethylene in Chhattisgarh.
                    While doing a cycle journey of about 400 kms through different villages, Mr. Dinesh Sharma and Mohd. Javed reached Raipur, the capital of Chhattisgarh. Here he met the Chief Minister of Chhattisgarh, Dr. Raman Singh and other ministers and presented the demand of the institution to ban the use of polythene in the state of Chhattisgarh. With the effect of this program of Rehana Foundation, polythene was banned by the government in Chhattisgarh.
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/c1.jpg').default}
                            text="Shri Dinesh Sharma and Mo. Javed"
                        />
                        <CardItem
                            src = {require('../images/c2.jpg').default}
                            text="Article published in the newspaper about Cycling for Environment Protection 2012"
                        />
                        <CardItem
                            src = {require('../images/c3.jpg').default}
                            text = "Member of the organization Mr. Dinesh Sharma"
                        />
                        <CardItem
                            src = {require('../images/c4.jpg').default}
                            text="Mo. Javed Khan"
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/c5.jpg').default}
                            text = "Making children and teachers aware of the environment"
                        />
                        <CardItem
                            src = {require('../images/c6.jpg').default}
                            text = "Making children and teachers aware of the environment"
                        />
                        <CardItem
                            src = {require('../images/c7.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/c8.jpg').default}
                            text="Shri Dinesh Sharma of the organization interacting with the children"
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/c9.jpg').default}
                            text = "Mohd Salim, Shri Dinesh Sharma and Mohd. meeting Chief Minister Dr. Raman Singh. Javed Khan"
                        />
                        <CardItem
                            src = {require('../images/c10.jpg').default}
                            text = "Meeting Mr. Rajesh Murat"
                        />
                        <CardItem
                            src = {require('../images/c11.jpg').default}
                            text = "While meeting Shri Ram Vichar Netam ji , Mohd Salim, Shri Dinesh Sharma and Mohd. Javed Khan"
                        />
                        <CardItem
                            src = {require('../images/c12.jpg').default}
                            text="Article published in the newspaper about Cycling for Environment Protection 2012"
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}

