import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


function GSP19 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Gandhi Sumiran Program 2019
                </h1>
                <p>
                    In Gandhi Sumiran Program 2019 , the main speaker of the program was eminent social worker and journalist Shri Ashutosh, he gave his views about Gandhi and Ambedkar - Dalit discourse on the subject of this program and the audience of the program asked their questions to Ashutosh and our speaker answered them as much as possible. lamps.
                    The program was presided over by Dr. Premsai Singh, Education Minister, Chhattisgarh and along with Dr. Ajay Tirkey, Mayor of Ambikapur city also increased the pride of the program.
                    The program was conducted by Javed Khan of our organization, thanks to all the respected people present in the program and who cooperated in the program were done by Shri Vishal Srivastava of the organization.
                    Special cooperation in the Gandhi Sumiran program was given by the district administration, district library respected Mr. Shafi Ahmed.                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/gs_2019_01.jpg').default}
                            text="In the beginning of Gandhi Sumiran program, Mohd. Javed's speech"
                        />
                        <CardItem
                            src = {require('../images/gs_2019_02.jpg').default}
                            text="Statement by Mr. Dinesh Sharma about the organization's program"
                        />
                        <CardItem
                            src = {require('../images/gs_2019_03.jpg').default}
                            text = "Wreath on the picture of Gandhiji by the President of the program Dr. Premsai Singh, Minister of Chhattisgarh Government"

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/gs_2019_04.jpg').default}
                            text="Keynote speaker Eminent social worker and journalist Shri Ashutosh's statement"
                        />
                        <CardItem
                            src = {require('../images/gs_2019_05.jpg').default}
                            text="Audience group"
                        />
                        <CardItem
                            src = {require('../images/gs_2019_6.jpg').default}
                            text='Batching keynote speaker'

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/gs_2019_07.jpg').default}
                            text="Statement of Dr. Premsai Singh during the program"
                        />
                        <CardItem
                            src = {require('../images/gs_2019_08.jpg').default}
                            text="Mayor of Ambikapur city Dr Ajay Tirkey addressing the audience"
                        />
                        <CardItem
                            src = {require('../images/gs_2019_10.jpg').default}
                            text='On 30 January 2019, members of the organization remembered Gandhiji by lighting a lamp on him.'
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}
export default GSP19
