import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import './Navbar.css';
function Navbar() {
    const [click, setClick] = useState(false);
    const [, setButton] = useState(true)

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if(window.innerWidth <=960) {
            setButton(false)
        } else {
            setButton(true);
        }
    };
    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize',showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <div onClick={closeMobileMenu}>
                        <img
                            src = {require('../images/logo.jpg').default}
                            id="logo"
                            alt="Rehana Foundation"
                        />
                    </div>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/programs' className='nav-links' onClick={closeMobileMenu}>
                                Programs
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/awards' className='nav-links' onClick={closeMobileMenu}>
                                Awards
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/members' className='nav-links' onClick={closeMobileMenu}>
                                Members
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/login' className='nav-links' onClick={closeMobileMenu}>
                                Admin Login
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar