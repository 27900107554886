import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function Cd18D () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Cloth Distribution Program 2018 (December)
                </h1>
                <p>
                    Day - Sunday 16/12/2018 <br/>
                    Location - Village Kham Khunt, Ghaton Mareya <br/>
                    In the year 2018, our organization distributed warm clothes and blankets to the needy people in village Kham Khunt, Ghaton Mareya without access to Udaipur development block of Surguja district. go to problems. There are villages of Korwa tribe on the hills of this village, this village is on Ramgarh hills and is devoid of all-weather road for traffic, there is no bridge on the river falling in the way and if it rains then there is no other means to reach the headquarters. And the terror of elephants is different, elephants break the kutcha houses of villagers, spoil their crops, there is a village where there is no school.                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd2018_1.jpg').default}
                            text = "Distribution of warm clothes to the villagers"
                        />
                        <CardItem
                            src = {require('../images/cd2018_2.jpg').default}
                            text = "Distributing the blankets"
                        />
                        <CardItem
                            src = {require('../images/cd2018_3.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/cd2018_7.jpg').default}
                            text = "Published on newspaper"

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd2018_4.jpg').default}
                            text = "Distribution of warm clothes to the villagers"

                        />
                        <CardItem
                            src = {require('../images/cd2018_5.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/cd2018_6.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/cd2018_8.jpg').default}
                            text = "Published on newspaper"
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}
