import React from 'react';
import '../../App.css'
import ContentsSection from "../ContentsSection";
import Cards from "../Cards";

function Home () {
    return (
        <>
            <ContentsSection />
            <Cards />

        </>
    );
}

export default Home;