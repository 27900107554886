import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function Ap17 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Annual Program 2017
                </h1>
                <p>
                    The annual program of 2018 was started by Shri Vishal Srivastava and Mohd. Javed happened by giving information about the work of the organization. In this program, Rehana Foundation Award was given to Mr. Vimal Kujur, who runs a school and hostel for blind children in Batauli block of Surguja district. This honor was given to him for doing special work in the field of social service. Along with this, Dr. Ram Shankar ji, a famous classical singer of Banaras Gharana, gave his performance in this program and he was also awarded the Rehana Foundation Award for his distinguished work in the field of music.
                    Vivekananda Vidyalaya had special cooperation in this program. On behalf of the organization, all the other associates were thanked by Mr. Dinesh Sharma and Mr. Anuj Sharma.
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/ap2017_1.jpg').default}
                            text="Chhattisgarh Legislative Assembly Leader of Opposition Shri TS Singh Deo presenting Rehana Foundation honor to Dr. Ram Shankar"
                        />
                        <CardItem
                            src = {require('../images/ap2017_2.jpg').default}
                            text="Dr. Ram Shankar's performance at the ceremony"
                        />
                        <CardItem
                            src = {require('../images/ap2017_3.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/ap2017_7.jpg').default}
                            text="Published article on newspaper about Annual Program 2017"
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/ap2017_4.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/ap2017_5.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/ap2017_6.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/ap2017_8.jpg').default}
                            text="Published article on newspaper about Annual Program 2017"
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}

