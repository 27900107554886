import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


function GSP17 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Gandhi Sumiran Program 2017
                </h1>
                <p>
                    In the Gandhi Sumiran program 2017, the key speakers of the program were Leader of Opposition of Chhattisgarh Legislative Assembly Shri TS Singh Deo and Mayor of Ambikapur Municipal Corporation Dr. Ajay Kumar Tirkey, the program was presided over by Shri Prabhu Narayan Verma. Gandhi Sumiran program started by Mohd. Javed Khan did it. Thanks to all the esteemed persons present in the program of the organization and who cooperated in the program were done by Mr. Dinesh Sharma of the organization. Special cooperation in the Gandhi Sumiran program of 2017 Government Girls U. mother. The school was from Ambikapur.
                </p>
            </div>
            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/gs2017_1.jpg').default}
                            text='Leader of Opposition in the Vidhan Sabha Shri TS Singh Deo and Mayor of Ambikapur Municipal Corporation Dr Ajay Kumar Tirkey garlanding the picture of Gandhiji'
                        />
                        <CardItem
                            src = {require('../images/gs2017_2.jpg').default}
                            text='Bhai Javed inaugurating the program'
                        />
                        <CardItem
                            src = {require('../images/gs2017_5.jpg').default}
                            text='Brother Dinesh Sharma giving vote of thanks in the program'
                            path='/awards'
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/gs2017_3.jpg').default}
                            text='Shri Prabhu Narayan Verma presiding over the program'
                        />
                        <CardItem
                            src = {require('../images/gs2017_4.jpg').default}
                            text="Shri TS Singh Dev delivering a lecture on Gandhiji's thoughts"
                        />
                        <CardItem
                            src = {require('../images/gs2017_6.jpg').default}
                            text='audience group'
                            path='/awards'
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default GSP17
