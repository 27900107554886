import React from 'react'
import './App.css';
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Route} from 'react-router-dom'
import './App.css';
import Home from './components/nav-pages/Home';
import Footer from "./components/Footer";
import Programs from './components/nav-pages/Programs';
import Awards from "./components/nav-pages/Awards";
import Members from "./components/nav-pages/Member";
import GSP17 from "./components/GSP17";
import GSP18 from "./components/GSP18";
import GSP19 from "./components/GSP19";
import Nv18 from "./components/Nv18";
import Cd16 from "./components/Cd16";
import Cd17 from "./components/Cd17";
import Cd18J from "./components/Cd18J";
import Cd18D from "./components/Cd18D";
import Ap16 from "./components/Ap16";
import Ap17 from "./components/Ap17";
import Ap18 from "./components/Ap18";
import MKZ from "./components/MKZ";
import PC17 from "./components/PC17";
import CEP from "./components/CEP";
import Login from "./components/nav-pages/Login";



function App() {
  return (
      <>
          <Router>
              <Navbar />
              <Route path='/' exact component={Home} />
              <Route path='/programs' component={Programs}/>
              <>
                  <Route path='/gsp17' component={GSP17}/>
                  <Route path='/gsp18' component={GSP18}/>
                  <Route path='/gsp19' component={GSP19}/>
                  <Route path='/nv18' component={Nv18}/>
                  <Route path='/cd16' component={Cd16}/>
                  <Route path='/cd17' component={Cd17}/>
                  <Route path='/cd18j' component={Cd18J}/>
                  <Route path='/cd18d' component={Cd18D}/>
                  <Route path='/ap16' component={Ap16}/>
                  <Route path='/ap17' component={Ap17}/>
                  <Route path='/ap18' component={Ap18}/>
                  <Route path='/mkz' component={MKZ}/>
                  <Route path='/pc17' component={PC17}/>
                  <Route path='/cep' component={CEP}/>


             </>

              <Route path='/awards' component={Awards}/>
              <Route path='/members' component={Members}/>
              <Route path='/login' component={Login}/>
              <Footer/>
          </Router>
      </>
  );
}

export default App;
