import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


export default function PC17 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Painting Competition 2017
                </h1>
                <p>
                    Our organization conducts many types of programs with the aim of recognizing and encouraging the creativity of children, one of which is a painting competition in which all the government and private school children of the city participated. This competition was divided into three classes, in the first class there were contestants from class 1 to 5, in the second class from class 6 to 8 and in the third class from class 9 to 12. The first, second and third winners were awarded in the annual program of the organization, this program was organized on January 8, 2017 at Gandhi Stadium in Ambikapur city.
                    Member of the organization Shri Dinesh Sharma Shri Anuj Sharma Mohd. Mohsim Mo Javed has contributed a lot,
                    see the highlights of this program
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/pc18_1.jpg').default}
                            text="Our team distributing drawing sheets to children"
                        />
                        <CardItem
                            src = {require('../images/pc18_2.jpg').default}
                            text="Children collecting drawing sheets"
                        />
                        <CardItem
                            src = {require('../images/pc18_3.jpg').default}
                            text = "Children competing in drawing competition"
                        />
                        <CardItem
                            src = {require('../images/pc18_4.jpg').default}
                            text = "Children competing in drawing competition"
                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/pc18_5.jpg').default}
                            text="Glimpse of competitors"
                        />
                        <CardItem
                            src = {require('../images/pc18_6.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/pc18_7.jpg').default}
                            text = "Audience group"
                        />
                        <CardItem
                            src = {require('../images/pc18_8.jpg').default}
                            text = "Audience group"
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}
