import React from 'react';

function CardItem(props) {
    return (
        <>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <li className='cards-item'>
                <div className='cards-item-link'>
                    <figure className='cards-item-pic-wrap'>
                        <img
                            className='cards-item-img'
                            alt='Images'
                            src={props.src}
                        />
                        <p>

                        </p>
                    </figure>
                    <div className='cards-item-info'>
                        <h5 className='cards-item-text'>{props.text}</h5>
                        <h5 className='cards-item-prof'>{props.line2}</h5>
                        <h5 className='cards-item-place'>{props.line3}</h5>
                    </div>
                </div>
            </li>
        </>
    );
}

export default CardItem;