import React from 'react'
import CardItem from "./CardItem";
import './Cards.css';
import './nav-pages/Pages.css'


function Cd16 () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    Cloth Distribution Program 2016
                </h1>
                <p>
                    In the year 2016, our organization did a camp in the remotest and unreachable villages of Surguja present Balrampur district, Chunchuna and Pundag. In this camp, health check-up of villagers and distribution of warm and normal clothes were done. Even today this area is without access, there are no bridges on the rivers and in the rainy season, they are cut off from the village headquarter, where no health facility can be reached.
                    To find out the problem of this village, Mr. Dinesh Sharma and Mohd. Javed worked tirelessly. Mr. Albret Minz, who awakened the light of education in this village, also contributed a lot to this program. To appreciate this noble work of Mr. Albret Minz, our organization has awarded him the Rehana Foundation Award for the year 2016
                </p>
            </div>

            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd16_001.jpg').default}
                            text="The inaccessible mountain road of Chunchna and Pundag"
                        />
                        <CardItem
                            src = {require('../images/cd16_010.jpg').default}
                            text="Published article on newspaper"
                        />
                        <CardItem
                            src = {require('../images/cd16_003.jpg').default}
                            text = "Mo-Javed while discussing the health of the villagers"

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd16_004.jpg').default}
                            text="Giving necessities to a small village"
                        />
                        <CardItem
                            src = {require('../images/cd16_005.jpg').default}
                            text="Giving necessities to a small village"
                        />
                        <CardItem
                            src = {require('../images/cd16_006.jpg').default}
                            text='Giving necessities to a small village'

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../images/cd16_007.jpg').default}
                            text="Mr. Dinesh Sharma distributing blankets"
                        />
                        <CardItem
                            src = {require('../images/cd16_008.jpg').default}
                        />
                        <CardItem
                            src = {require('../images/cd16_009.jpg').default}
                            text='Mo-Javed distributing blankets'
                        />
                    </ul>

                </div>
            </div>
        </div>
    );
}
export default Cd16
