import React from 'react'
import CardItem from "../CardItem";
import '../Cards.css';
import './Pages.css'


function Members () {
    return (
        <div className='cards'>
            <script>
                function scrollToTop() {
                window.scrollTo(0, 0)
            }
            </script>
            <div className='heading-texts'>
                <h1>
                    List of Members of Rehana Foundation
                </h1>
            </div>
            <div className="cards-container2">
                <div className="cards-wrapper">
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../../images/mem_harikishan.jpg').default}
                            text="Shri Hari Kishan Sharma"
                            line2="Social Worker"
                            line3="Ambikapur"
                        />
                        <CardItem
                            src = {require('../../images/mem_shashid.jpg').default}
                            text="Md Shahid Khan"
                            line2="Advocate"
                            line3="Ambikapur"
                        />
                        <CardItem
                            src = {require('../../images/mem_afroz.jpg').default}
                            text="Md. Afroz"
                            line2="Media Professional"
                            line3="Bhatgaon"

                        />
                        <CardItem
                            src = {require('../../images/mem_didi.jpg').default}
                            text="Yashmeen Khan"
                            line2="Educator"
                            line3="Bhatgaon"
                        />
                        <CardItem
                            src = {require('../../images/mem_bhabhi.jpg').default}
                            text="Jahan Aara Khan"
                            line2="Educator"
                            line3="Ambikapur"
                        />
                        <CardItem
                            src = {require('../../images/mem_dinesh.jpg').default}
                            text="Shri Dinesh Sharma"
                            line2="Tax Practitioner"
                            line3="Ambikapur"

                        />
                        <CardItem
                            src = {require('../../images/mem_raja.jpg').default}
                            text="Shri T S Dhanjal"
                            line2="Tax Practitioner"
                            line3="Ambikapur"

                        />
                    </ul>
                    <ul className='cards-items'>
                        <CardItem
                            src = {require('../../images/mem_dilip.jpg').default}
                            text="Shri Dilip Gupta"
                            line2="Medical Professional"
                            line3="Udaipur"

                        />
                        <CardItem
                            src = {require('../../images/mem_anuj.jpg').default}
                            text="Shri Anuj Sharma"
                            line2="Medical Professional"
                            line3="Ambikapur"

                        />
                        <CardItem
                            src = {require('../../images/mem_vaibhav.jpg').default}
                            text="Shri Vaibhav Tripathi"
                            line2="Medical Professional"
                            line3="Manendragarh"
                        />
                        <CardItem
                            src = {require('../../images/mem_keval.jpg').default}
                            text="Shri Keval Sahoo"
                            line2="I.T. and Computer Professional"
                            line3="Ambikapur"

                        />
                        <CardItem
                            src = {require('../../images/mem_mohseem.jpg').default}
                            text="Md. Mohseem"
                            line2="Medical Professional"
                            line3="Ambikapur"

                        />
                        <CardItem
                            src = {require('../../images/mem_jawed.jpg').default}
                            text="Md. Jawed Khan"
                            line2="Medical Professional"
                            line3="Ambikapur"

                        />
                        <CardItem
                            src = {require('../../images/mem_vishal.jpg').default}
                            text="Vishal Shrivastava"
                            line2="Education & IT Professional"
                            line3="Ambikapur"

                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default Members
